//button colors
$button: #e54f20;
$button-hover: lighten($button, 10%);
$button-active: darken($button, 10%);
$button-disabled: #dadcdd;

//default colors
$primary-orange: #e54f20;
$dark-orange: #e97802;
$text-color: #474e56;
$dark-text: #242424;
$white: #ffffff;

//background colors
$background-gray: #e4eae8;
$light-gray: #7e8389;
$tab-color: #b5b8bb;
$header-color: #161616;
$code-background: #eff1f3;
$tabs-background: #ebebeb;

//borders
$border-color: #474e56b2;
$border-disabled: #474e5666;
$border-active: #e54f20;
$sidebar-border: #ebebeb;
$dark-border: #242424;
$tabs-border: #7e8389;

//shadows
$card-shadow: #e3e1e1;

//loader colors
$light-orange: #fae6e0;
$dark-orange-loader: #e54f20;
$gray-loader: #161616;

//device states
$pending: #fdecc8;
$backoff: #fadec9;
$lost: #ffe2dd;
$deleted: #ffe2dd;
$active: #dbeddb;

//spo state colors
$spo-off: #d44c47;
$spo-on: #448361;

$deep-orange: #e54f20;
$light-melon: #fae6e0;
$spray: #86eef5;
$opal: #a9c4c4;
$platinum: #e4eae8;
$white: #fcfcfc;
$black: #161616;
